import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
       <Helmet>
        <title>Carambola AI - Privacy Policy</title>
       </Helmet>      
      <h1>Privacy Policy</h1>
      <p>[Last Modified: October 11, 2023]</p>
      <p>This privacy policy (<b>“Privacy Policy”</b> or <b>“Policy”</b>) describes how Carambola Media Ltd. and its affiliated companies and subsidiaries (collectively shall be referred to as <b>“Carambola”</b>, <b>“we”</b>, <b>“us”</b>, or <b>“our”</b>) collect, use and disclose certain information, and the choices you can make about that information.</p>
      <p>Carambola is the developer and owner of the technology and platform (<b>“Platform”</b>) that enables publishers, operating websites, applications or other digital assets (<b>“Digital Assets”</b> and <b>“Publishers”</b> respectively) to embed and share within the Digital Assets interactive units including, but not limited, online quizzes, questionnaires, surveys, feedback applications or other features (<b>“Interactive Unit”</b>) for the purpose of creating end user engagement. The Interactive Units can further include advertisements and other promotional content (<b>“Ads”</b>) provided by third party advertisers (<b>“Advertisers”</b>) (all together shall be referred to as <b>“Services”</b>).</p>
      <p>We collect and process certain information when visitors browse our website: https://carambola.com/ (<b>“Visitors”</b> and <b>“website”</b>), we collect and use certain information about the Publishers using our Platform and Services or on behalf of the Advertiser or Publisher, such as information on the end users, the individuals watching the ads or interacting with the Interactive Units (as applicable the <b>“end users”</b>, <b>“Visitors”</b> and <b>“Publishers”</b> collectively shall be referred to as <b>“you”</b>).</p>
      <p>This Privacy Policy applies to all information about you that we collect in connection with the Services throughout the world, and explains what data we may collect from you, how such data may be used or shared with others, how we safeguard it, and how you may exercise your rights related to your Personal Data (as defined below) under the applicable privacy laws such as the EU General Data Protection Regulation (<b>“GDPR”</b>) and the California Consumer Privacy Act (<b>“CCPA”</b>) and other US states as further detailed below.</p>
      <p><b>CONTENTS</b></p>
      <ol>
        <li>POLICY AMENDMENTS:</li>
        <li>CONTACT INFORMATION AND DATA CONTROLLER INFORMATION:</li>
        <li>DATA SETS WE COLLECT AND FOR WHAT PURPOSE:</li>
        <li>HOW WE COLLECT YOUR INFORMATION:</li>
        <li>COOKIES AND SIMILAR TECHNOLOGIES:</li>
        <li>DATA SHARING – CATEGORIES OF RECIPIENTS WE SHARE PERSONAL DATA WITH:</li>
        <li>USER RIGHTS AND OPT-OUT OPTIONS:</li>
        <li>DATA RETENTION:</li>
        <li>SECURITY MEASURES:</li>
        <li>INTERNATIONAL DATA TRANSFER:</li>
        <li>ELIGIBILITY AND CHILDREN PRIVACY:</li>
        <li>JURISDICTION SPECIFIC NOTICES:</li>
        <li>ADDITIONAL NOTICE TO CALIFORNIA RESIDENTS</li>
        <li>ADDITIONAL NOTICE TO COLORADO RESIDENTS</li>
      </ol>
      <p>YOUR RIGHTS UNDER CPA:</p>
      <p>HOW TO SUBMIT A REQUEST UNDER CPA?</p>
      <ol>
        <li>ADDITIONAL NOTICE TO VIRGINIA RESIDENTS</li>
      </ol>
      <p>HOW TO SUBMIT A REQUEST UNDER VCDPA?</p>
      <ol>
        <li>ADDITIONAL NOTICE TO CONNECTICUT RESIDENTS</li>
      </ol>
      <p>HOW TO SUBMIT A REQUEST UNDER CDPA?</p>
      <ol>
        <li>ADDITIONAL NOTICE TO UTAH RESIDENTS (effective January 2024)</li>
        <li>NOTICE TO NEVADA RESIDENTS</li>
        <li><b>POLICY AMENDMENTS:</b></li>
      </ol>
      <p>We reserve the right to amend this Privacy Policy from time to time, at our sole discretion. The most recent version of the Privacy Policy will always be posted on the website and the update date will be reflected in the “Last Modified” heading. We will provide notice to you if these changes are material, and, where required by applicable law, we will obtain your consent. Any amendments to the Privacy Policy will become effective within 30 days upon the display of the modified Privacy Policy. We recommend you review this Privacy Policy periodically to ensure that you understand our most updated privacy practices.</p>
      <ol>
        <li><b>CONTACT INFORMATION AND DATA CONTROLLER INFORMATION:</b></li>
      </ol>
      <p>Carambola Media Ltd. is incorporated under the laws of Israel, and is the <b>Controller</b> (as such term is defined under the GDPR or equivalent privacy legislation) of your Personal Data.</p>
      <p>For any question, inquiry or concern related to this Privacy Policy or the processing of your Personal Data, you may contact as follows:</p>
      <p><b>DPO CONTACT INFORMATION:</b></p>
      <p>Carambola Media Ltd.</p>
      <p>10 Aba Even Blvd. Building C, Herzliya, 4672528, Israel.</p>
      <p>By Email: <a href="mailto:contact@carambo.la" target="_self"><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></a><b>.</b></p>
      <ol>
        <li><b>DATA SETS WE COLLECT AND FOR WHAT PURPOSE:</b></li>
      </ol>
      <p>You can find here information regarding the data sets we collect, purposes for which we process your data as well as our lawful basis for processing, and how the data is technically processed. In general, we may collect two types of information from you, depending on your interaction with us:</p>
      <p><em><b>Non-Personal Data</b></em></p>
      <p>During your interaction with our Services, we may collect aggregated, non-personal non-identifiable information, which may be made available or gathered via your access to and use of the Services (“<b>Non-Personal Data</b> “). We are not aware of the identity of the user from which the Non-Personal Data is collected. The Non-Personal Data being collected may include your aggregated usage information and technical information transmitted by your device, such as: the type of browser or device you use, operating system type and version, language preference, time and date stamp, country location, etc.</p>
      <p><em><b>Personal Data</b></em></p>
      <p>We may&nbsp;also collect from you, during your access or interaction with the Services, individually identifiable information, namely information that identifies an individual or may, with reasonable effort, be used to identify an individual (“<b>Personal Data</b>” or “<b>Personal Information</b>”). The types of Personal Data that we collect as well as the purpose for processing such data are specified in the table below.</p>
      <p>For the avoidance of doubt, any Non-Personal Data connected or linked to any Personal Data shall be deemed as Personal Data as long as such connection or linkage exists.</p>
      <p>The table below details the processing of Personal Data we collect, the purpose, lawful basis, and processing operations:</p>
      <p><b>DATA SET</b></p>
      <p><b>PURPOSE AND OPERATIONS</b></p>
      <p><b>LAWFUL BASIS</b></p>
      <p><b>VISITORS</b></p>
      <p><b>Online Identifiers</b>:</p>
      <p>When you access and interact with our website, we collect certain online identifiers, such as Cookie ID, agent ID, internet protocol (IP) address or similar unique online identifiers generated (“<b>Online Identifiers</b>”).</p>
      <p>We process such data through our first party cookies to enable the operation of the website, security and for fraud prevention purposes. The first party cookies are strictly necessary.</p>
      <p>We process such data based on our legitimate interest.</p>
      <p><b>Contact Information:</b></p>
      <p>If you voluntarily contact us for our interest in our Services, support or other inquiries, you may be required to provide us with certain information such as your full name, phone number, company name, email address, and any additional information you decide to share with us.</p>
      <p>We process such data to respond to your inquiry.</p>
      <p>We process such data subject to our legitimate interest in order to respond to your inquiry.</p>
      <p>We may keep such correspondence if we are legally required to.</p>
      <p><b>Publishers and End Users</b></p>
      <p><b>Account Information:</b></p>
      <p>Publishers are designated with an account, or are able to register and create an account through the website, the account registration information includes your full name, email address and possibly you phone number.</p>
      <p>Additionally, during the registration process you will be required to create a username and password.</p>
      <p>This information will be processed when you login as well.</p>
      <p>You represent and warrant that you will not provide us with inaccurate, misleading or false information.</p>
      <p>We process such data to create you an account, provide you with account management, support and to provide you the Services as well as to send you needed information related to our business engagement (e.g., send you a welcome message, notify you regarding any updates to our Services, send applicable invoices, etc.) and additional occasional communications and updates related to the Services, as well as promotional and marketing emails (Direct Marketing as stated below).</p>
      <p>We may also use the information in order to verify your identity.</p>
      <p>We process such data for the purpose of performing our contract with you, meaning, to provide the Services and to designate your account.</p>
      <p><b>Usage Data:</b></p>
      <p>We collect certain usage data, meaning analytic and statistics on how the Publishers use our Services and the dashboard available through the Platform (“<b>Usage Data</b>”).</p>
      <p>We process such aggregated Usage Data with respect to the Platform and Services in order to operate, provide, maintain, protect, manage and improve Carambola Platform tools and Services.</p>
      <p>We process such data subject to our legitimate interest.</p>
      <p><b>Direct Marketing:</b></p>
      <p>As a Publisher, we will send you invoices, materials and marketing content through the email information you provided during your onboarding.</p>
      <p>We process such data to keep you updated with offers and content such as updates, new capabilities and features, and to send you invoices and supporting documentation.</p>
      <p>We process such information subject to our legitimate interest. You can opt-out at any time by using the “<b>unsubscribe</b>” option or by contacting us via <a href="mailto:contact@carambo.la" target="_self"><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></a><b>.</b></p>
      <p>Note however that certain content (such as invoices) will still be sent.</p>
      <p><b>End user interaction with Digital Assets and Interactive Units:</b></p>
      <p>We will collect certain information from the end users when interacting with the Publisher’s Digital Assets to determine which Interactive Unit shall be placed. we may use cookies or other similar technologies as detailed in our Cookies Policy, and we use certain online identifiers to collect certain information includes: the type of content viewed, session duration, page impressions(“<b>Contextual Information</b>”).</p>
      <p>We also place a unique identifier such as Internet protocol ("<b>IP</b>") address, the domain name that served you to access the Interactive Unit and advertising ID , which are used by our advertiser to provide you with personalized ads based on the data the advertiser collect and process on the End User ("<b>End User Unique Identifier</b>").</p>
      <p>With respect to the domain name, our system will only use it on real time to make a decision about the type of content to display to you. Such information shall be attributed to a general user ID that we generate for each User on the system and is a completely random identifier.</p>
      <p>For the purpose of providing the Publisher with reports on how the end user interacts with the Interactive Units, to identify the end users’ preference, to ensure the end user is real and not fraud, to enable personalized ads display.</p>
      <p>For our internal tracking and reporting purposes.</p>
      <p>As part of the efforts to provide our Services we may process the End User Unique Identifiers for the following purposes:</p>
      <ol>
        <li>to determine which Interactive Units shall be placed, which is not based on personalization or profiling information<b>;</b></li>
        <li>Use limited data to select advertising such as the End User’s website or app, non-precise location, device type or the content such End User is (or has been) interacting with;</li>
        <li><b>(iii)</b>Allow our Advertisers to Create and improve End User’s profiles for presenting End-Users with more relevant advertising based on their possible interests<b>;</b></li>
        <li>provide End Users with personalized advertising based on the profile detailed above;</li>
        <li>Allow our Advertisers to create profiles for personalized advertising by combining the data we collect with other information about the end user or similar user;</li>
        <li>Ensure security, prevent and detect fraud, and fix error in order to ensure that systems and processes work properly and securely<b>.</b></li>
      </ol>
      <p>The ads presented do you may be provided to you based on the processing of the Contextual Information which is not&nbsp;based on based on personalization or profiling information or End User Unique Identifier based on our advertiser's preference and choice and subject to the End User consent.</p>
      <p>Our legal basis for processing of the End User Unique Identifiers is depending on the specific purpose of the processing activities:</p>
      <ol>
        <li>Use limited data to select advertising – End User’s consent</li>
        <li>Create profiles for personalized advertising – End User’s consent</li>
        <li>Use profiles to select personalized advertising – End User’s consent</li>
        <li>Create profiles for personalized advertising – End User’s consent</li>
        <li>Use profiles to select personalized content - End User’s consent</li>
        <li>Ensure security, prevent and detect fraud, and fix error - Legitimate Interest</li>
      </ol>
      <p>We process this data on behalf of our partners who are legally required to obtain your consent before allowing us to process such information.</p>
      <p><b>End user interaction with ads:</b></p>
      <p>In an effort of delivering relevant advertising to the end users, we collect and share with the Advertisers the following (depending on the end user’s preference):</p>
      <ul>
        <li>the web pages visited or applications used by the end user, the type of content seen.</li>
        <li>Approximate location (extracted from the IP) which includes: country, city, zip, etc.</li>
        <li>Online Identifiers, including the advertiser unique ID and the Consent String (such as TCF).</li>
      </ul>
      <p>(collectively “<b>Ad Data</b>”)</p>
      <p>We process such data to improve the way in which we offer the Services to our Publishers by: (ii) enhancing the end user’s experience by delivering more relevant advertising and to personalize ads to the end user, as well as to monitor, measure, analyze and optimize the end users’ use of advertisement, including analyzing seasonal and annual usage trends; and</p>
      <p>(iii) auditing and tracking usage statistics and traffic flow as well as fraud detection, providing reports related to the Services, new functionality, features and insights on the end users, all which we provide to the Publisher.</p>
      <p>We process such data for the purpose of performing our contract with our Publisher, such as providing reports and determining payment.</p>
      <p>Where we process such data for the purposes of providing personalized advertising, we will process this data solely upon indirectly receiving consent from the end user through the Publishers consent management platform.</p>
      <p>You may withdraw your consent at any time through the Publishers’ cookie settings or through the opt-out options detailed below.</p>
      <p>If the end user did not provide consent, the ads displayed shall be contextual ads.</p>
      <p>Please note that the actual <b>processing operation</b> per each purpose of use and lawful basis detailed in the table above may differ. Such processing operation usually includes a set of operations made by automated means, such as collection, storage, use, disclosure by transmission, erasure, or destruction. The transfer of Personal Data to third-party countries, as further detailed in the <em>“International Data Transfer”</em> Section below, is based on the same lawful basis as stipulated in the table above.</p>
      <p>In addition, we may use certain Personal Data to prevent potentially prohibited or illegal activities, fraud, misappropriation, infringements, identity thefts, and any other misuse of the Services and to enforce the Terms, as well as to protect the security or integrity of our databases and the Services, and to take precautions against legal liability. Such processing is based on our legitimate interests.</p>
      <p>We may collect different categories of Personal Data and Non-Personal Data from you, depending on the nature of your interaction with the Services provided through the website and Platform, as detailed above. If we combine Personal Data with Non-Personal Data, the combined information will be treated as Personal Data or for as long as it remains combined.</p>
      <p>Please further be advised that, as an end user interacting with the Interactive Unit, you might be subject to additional data collection directly on behalf of Publishers (meaning the Publishers operating the Digital Assets in which our Interactive Unit are implemented, as well as Advertisers providing the ads displayed in the Interactive Unit). We are not responsible, nor do we monitor such data collection, that shall be governed by such Publishers’ privacy policies.</p>
      <ol>
        <li><b>HOW WE COLLECT YOUR INFORMATION:</b></li>
      </ol>
      <p>Depending on the nature of your interaction with us, we may collect the above detailed information from you, as follows:</p>
      <ul>
        <li>Automatically, when you visit our website, interact with our Platform or use our Services, including through the use of Cookies (as detailed below) and similar tracking technologies.</li>
        <li>When you voluntarily choose to provide us with information, such as when you contact us, all as detailed in this Policy.</li>
        <li>Provided by our partners and Cookie Management Platforms implemented in our partners' assets.</li>
      </ul>
      <ol>
        <li><b>COOKIES AND SIMILAR TECHNOLOGIES:</b></li>
      </ol>
      <p>We use “cookies” (or similar tracking technologies) when you access <b>our website</b>. The use of cookies is a standard industry-wide practice. A “cookie” is a small piece of information that a website assigns and stores on your computer while you are viewing a website. Cookies can be used for various purposes, including allowing you to navigate between pages efficiently, as well as for statistical purposes, analytic purposes and marketing.</p>
      <ol>
        <li><b>DATA SHARING – CATEGORIES OF RECIPIENTS WE SHARE PERSONAL DATA WITH:</b></li>
      </ol>
      <p>We share your data with third parties, including the Publishers or service providers that help us provide our Services. You can find here information about the categories of such third-party recipients.</p>
      <p><b>CATEGORY OF RECIPIENT</b></p>
      <p><b>DATA THAT WILL BE SHARED</b></p>
      <p><b>PURPOSE OF SHARING</b></p>
      <p>Advertisers</p>
      <p>Contextual Information, Ad Data and End User Unique Identifiers</p>
      <p>We provide such information to our Advertisers, so they will be able to bid for content that best suits such a web-page, as a part of our Services. We will further present aggregated data about end users’ interaction with the ads, to provide reports to our Publishers.</p>
      <p>The Advertisers is required to secure the data and to use the data for pre-agreed purposes only, while ensuring compliance with all applicable data protection regulations. Carambola and the Advertiser has engaged in the Module II of the Standard Contractual Clauses.</p>
      <p>The transferred data shall be encrypted when transferred.</p>
      <p>Publisher</p>
      <p>Ad Data</p>
      <p>Providing reports and analytic to the Publisher.</p>
      <p>The Publisher is required to secure the data and to use the data for pre-agreed purposes only, while ensuring compliance with all applicable data protection regulations. Carambola and the Publisher has engaged in the Module II of the Standard Contractual Clauses.</p>
      <p>The transferred data shall be encrypted when transferred</p>
      <p>Service Providers</p>
      <p>All data</p>
      <p>We may disclose Personal Data to our trusted agents (such as legal counsel) and service providers (including, but not limited to, our Cloud Service Provider, Analytics Service Provider, CRM provider, etc.) so that they can perform the requested services on our behalf. Thus, we share your data with third party entities, for the purpose of storing such information on our behalf, or for other processing needs. These entities are prohibited from using your Personal Data for any purposes other than providing us with requested services.</p>
      <p>Any acquirer of our business</p>
      <p>All data</p>
      <p>We may share Personal Data, in the event of a corporate transaction (e.g., sale of a substantial part of our business, merger, consolidation or asset sale). In the event of the above, our affiliated companies or acquiring company will assume the rights and obligations as described in this Policy.</p>
      <p>Affiliated Companies</p>
      <p>All Data</p>
      <p>We may share aggregate or Non-Personal Data with our affiliated companies and additional third parties in accordance with the terms of this Policy. We may store any type of information on our servers or cloud servers, use or share Non-Personal Data in any of the above circumstances, as well as for the purpose of providing and improving our Services, aggregate statistics, marketing and conduct business and marketing analysis, and to enhance your experience.</p>
      <p>Legal and law enforcement</p>
      <p>Subject to law enforcement authority request.</p>
      <p>We may disclose certain data to law enforcement, governmental agencies, or authorized third parties, in response to a verified request relating to terror acts, criminal investigations or alleged illegal activity or any other activity that may expose us, you, or any other user to legal liability, and solely to the extent necessary to comply with such purpose.</p>
      <p>When we share information with services providers and Publishers, we ensure they only have access to such information that is strictly necessary for us to provide the Services. These parties are required to secure the data they receive and to use the data for pre-agreed purposes only while ensuring compliance with all applicable data protection regulations (such service providers may use other non-personal data for their own benefit).</p>
      <ol>
        <li><b>USER RIGHTS AND OPT-OUT OPTIONS:</b></li>
      </ol>
      <p>We acknowledge that different people have different privacy concerns and preferences. Our goal is to be clear about what information we collect so that you can make meaningful choices about how it is used. We allow you to exercise certain choices, rights, and controls in connection with your information. Depending on your relationship with us, your jurisdiction and the applicable data protection laws that apply to you, you have the right to control and request certain limitations or rights to be executed. Please note, if you are an individual who interacts with a Publisher and you contact us regarding your rights, you will be directed to contact the applicable Publisher as the “controller” of the Personal Data.</p>
      <p>For additional rights under various jurisdictions, please refer to Section ‎12 “JURISDICTION-SPECIFIC NOTICES” herein below. For detailed information on your rights and how to exercise your rights, please see the Data Subject Request Form (“<b>DSR</b>”) available <a href="https://74f580aa-c66e-4d52-bdab-78d31b2a3662.usrfiles.com/ugd/bb3092_c09529d52d764697a1701ac876d19c43.pdf" target="_blank"  rel="noreferrer"><u>HERE</u></a> or send it to: <a href="mailto:contact@carambo.la" target="_self"><u><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></u></a><u><b>.</b></u></p>
      <p>Further, you may execute certain rights without the need to fill out the DSR Form, such as: You can correct or delete the Contact Information or Account information at any time, through the account settings, You can you can opt-out from receiving our emails by clicking “unsubscribe” link, You can withdraw consent for processing End User Unique Identifier for analytics or marketing purposes, at any time be using the cookie settings on the website, Use the “Do Not Sell or Share My Information” through the first-party business, i.e., through the cookie consent manager presented on the Publisher's website, etc.</p>
      <p>To opt out from cross contextual ads you can further use these links: the Network Advertising Initiative’s (“<b>NAI</b>”) website: <a href="http://optout.networkadvertising.org/#/" target="_blank"  rel="noreferrer">NAI consumer opt-out</a> and the Digital Advertising Alliance’s (“<b>DAA</b>”) website: <a href="http://www.aboutads.info/" target="_blank"  rel="noreferrer">DAA opt-out page</a>. Or the European Interactive Digital Advertising Alliance (“<b>EDAA</b>”) website: <a href="http://www.youronlinechoices.eu/" target="_blank"  rel="noreferrer">Your Online Choices page</a>.</p>
      <ol>
        <li><b>DATA RETENTION:</b></li>
      </ol>
      <p>We retain Personal Data we collect as long as it remains necessary for the purposes set forth above, all in accordance with applicable laws, or until an individual expresses a preference to opt-out.</p>
      <p>Other circumstances in which we will retain your Personal Data for longer periods of time include: (i) where we are required to do so in accordance with legal, regulatory, tax, or accounting&nbsp;requirements; (ii) for us to have an accurate record of your dealings with us in the event of any complaints or challenges; or (iii) if we reasonably believe there is a prospect of litigation relating to your Personal Data. Please note that except as required by applicable law, we may at our sole discretion, delete or amend information from our systems, without notice to you, once we deem it is no longer necessary for such purposes.</p>
      <ol>
        <li><b>SECURITY MEASURES:</b></li>
      </ol>
      <p>We work hard to protect the Personal Data we process from unauthorized access, alteration, disclosure, or destruction. We have implemented physical, technical, and administrative security measures for the Services that comply with applicable laws and industry, such as encryption using SSL, we minimize the amount of data that we store on our servers, restricting access to Personal Data to Carambola employees, contractors, and agents, etc. Note that we cannot be held responsible for unauthorized or unintended access beyond our control, and we make no warranty, express, implied, or otherwise, that we will always be able to prevent such access.</p>
      <p>Please&nbsp;contact us at<em>: </em><a href="mailto:contact@carambo.la" target="_self"><u><b>contact@carambo.la</b></u></a><u> </u>if you feel that your privacy was not dealt with properly, in a way that was in breach of our Privacy Policy, or if you become aware of a third party's attempt to gain unauthorized access to any of your Personal Data. We will make a reasonable effort to notify you and the appropriate authorities (if required by applicable law) in the event that we discover a security incident related to your Personal Data.</p>
      <ol>
        <li><b>INTERNATIONAL DATA TRANSFER:</b></li>
      </ol>
      <p>Our data servers in which we host and store the information are located in the Israel. The Company’s HQ are based in Israel in which we may access the information stored on such servers or other systems such as the Company’s ERP, CRM, Salesforce, and other systems. In the event that we need to transfer your Personal Data out of your jurisdiction, we will take appropriate measures to ensure that your Personal Data receives an adequate level of protection as required under applicable law. Furthermore, when Personal Data that is collected within the European Economic Area ("<b>EEA</b>") is transferred outside of the EEA to a country that has not received an adequacy decision from the European Commission, we will take necessary steps in order to ensure that sufficient safeguards are provided during the transferring of such Personal Data, in accordance with the provision of the standard contractual clauses approved by the European Union. Thus, we will obtain contractual commitments or assurances from the data importer to protect your Personal Information, using contractual protections that EEA and UK regulators have pre-approved to ensure your data is protected (known as standard contract clauses), or rely on adequacy decisions issued by the European Commission. Some of these assurances are well-recognized certification schemes.</p>
      <ol>
        <li><b>ELIGIBILITY AND CHILDREN PRIVACY:</b></li>
      </ol>
      <p>The Services are not intended for use by children (the phrase "child" shall mean an individual that is under the age defined by applicable law, which concerning the EEA is under the age of 16, and with respect to the US, under the age of 13), and we do not knowingly process children's information. We will discard any information we receive from a user that is considered a "child" immediately upon discovering that such a user shared information with us. Please contact us at:&nbsp;<a href="mailto:contact@carambo.la" target="_self"><u><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></u></a><b> </b>if you have reason to believe that a child has shared any information with us.</p>
      <ol>
        <li><b>JURISDICTION SPECIFIC NOTICES:</b></li>
        <li><b>ADDITIONAL NOTICE TO CALIFORNIA RESIDENTS</b></li>
      </ol>
      <p>This section applies only to California residents. Pursuant to the California Consumer Privacy Act of 2018 (“<b>CCPA</b>”) effective November 2020, and as amended by the CPRA, effective January 1, 2023.</p>
      <p>Please see the CCPA Privacy Notice which discloses the categories of personal information collected, purpose of processing, source, categories of recipients with whom the personal information is shared for a business purpose, whether the personal information is sole or shared, the retention period, and how to exercise your rights as a California resident.</p>
      <ol>
        <li><b>ADDITIONAL NOTICE TO COLORADO RESIDENTS</b></li>
      </ol>
      <p>Under the Colorado Privacy Act (“<b>CPA</b>”) if you are a resident of Colorado, acting <b>only</b> as an individual or household context (and <b>not</b> in a commercial or employment context, as a job applicant or as a beneficiary of someone acting in an employment context), your rights with respect to your personal data are described below.</p>
      <p>“<b>Personal Data</b>” as defined in the CPA means: “<em>information that is linked or reasonably linkable to an identified or identifiable individual”</em> and does <b>not</b> include any of the following: <em>publicly available information, de-identified or aggregated consumer, and information excluded from the CPA scope, such as: Health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPPA) or 42 CFR Part 2- “Confidentiality Of Substance Use Disorder Patient Records”, Personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or and the Driver’s Privacy Protection Act of 1994, Children’s Online Policy Protection Act of 1998 (COPPA), Family Educational Rights and Privacy Act of 1974, national Security Exchange Act of 1934, higher education data and employment data.</em></p>
      <p><b>Sensitive Data</b> includes <b>(i)</b> racial or ethnic origin, religious beliefs, mental or physical health condition or diagnosis, sex life or sexual orientation; <b>(ii)</b> Genetic or biometric data that can be processed to uniquely identify an individual; or <b>(iii)</b> child data. We do not process or collect any sensitive data.</p>
      <p>Section 3 “Data Sets we Collect and for What Purposes” of the Privacy Policy, we describe our collection and processing of personal data, the categories of personal data that are collected or processed, and the purposes. Additionally, in Section 6 “Data Sharing – Categories of Recipients we Share Personal Data With” details the categories of third-parties the controller shares for business purposes.</p>
      <p><b>YOUR RIGHTS UNDER CPA</b>:</p>
      <p>Herein below, we will detail how consumers can exercise their rights, and appeal such decision, or if Carambola sells the personal data, or sells the personal data for advertising and how to opt-out.</p>
      <p><b>Right to Access/ Right to Know</b></p>
      <p>You have the right to confirm whether and know the Personal Data we collected on you</p>
      <p>You can exercise your right by reviewing this Privacy Policy, in case you would like to receive the Personal Data please fill in this form to receive a copy of your data</p>
      <p><b>Right to Correction</b></p>
      <p>You have the right to correct inaccuracies in your Personal Data, taking into account the nature of the Personal Data and the purposes of the processing of your Personal Data.</p>
      <p>You can exercise this right directly through your account or by filling in this form</p>
      <p><b>Right to Deletion</b></p>
      <p>You have the right to delete the Personal Data, this right is not absolute and in certain circumstances we may deny such request. We may deny your deletion request, in full or in part, if retaining the information is necessary for us or our service provider(s) for any of the following reasons: <b>(1)</b> Complete the transaction for which we collected the Personal Data, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you; <b>(2) </b>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities; <b>(3)</b> Debug products to identify and repair errors that impair existing intended functionality;<b> (4) </b>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law; <b>(5) </b>&nbsp;Comply with the law or legal obligation; <b>(6)</b> Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent; <b>(7)</b> Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us; <b>(8)</b> Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</p>
      <p>We will delete or de-identify personal information not subject to one of these exceptions from our records and will direct our processors to take similar action.</p>
      <p>If you would like to delete your Personal Data please fill in this form.</p>
      <p>You do not need to create an account with us to submit a request to know or delete.</p>
      <p><b>Right to Portability</b></p>
      <p>You have the right to obtain the personal data in a portable, and to the extent technically feasible, readily usable format that allows you to transmit the data to another entity without hindrance.</p>
      <p>If you would like to receive the Personal Data please fill in this form to receive a copy of your data we will select a format to provide your Personal Data that is readily usable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
      <p><b>Right to opt out from selling Personal Data</b></p>
      <p>You have the right to opt out of the sale of your Personal Data for the purposes of targeted advertising, sale to a third party for monetary gain, or for profiling in furtherance of decisions that produce legal or similarly significant effects concerning you or any other consumer.</p>
      <p>You may authorize another person acting on your behalf to opt out, including by broad technical tools, such as DAA, NAI, etc.</p>
      <p>Carambola does not sell your personal information, so we do not offer an opt out. Carambola may “share” personal information with third parties for personalized advertising purposes. You may indicate your choice to opt-out of the sharing of your personal data with third parties for personalized advertising on third party sites as detailed in Section 7 "Users Rights and Opt Out Options".</p>
      <p>To opt out from the use of cookies on our website, please click the “do not sell or share my personal information” in the footer of the website which will enable you to customize the use of cookies on our website.</p>
      <p><b>Right to opt out from Targeted Advertising</b></p>
      <p><b>Right to opt out from Profiling</b></p>
      <p>We do not profile you, thus we do not need to provide an opt-out.</p>
      <p><b>Right to Appeal</b></p>
      <p>If we decline to take action on your request, we shall so inform you without undue delay, within 45 days of receipt of your request. The notification will include a <b>justification</b> for declining to take action and instructions on <b>how you may appeal</b>.</p>
      <p>If we deny the appeal, you may contact the Colorado Attorney General using this link: <a href="https://coag.gov/office-sections/consumer-protection/" target="_blank"  rel="noreferrer"><a data-auto-recognition="true" href="https://coag.gov/office-sections/consumer-protection/" target="_blank"  rel="noreferrer">https://coag.gov/office-sections/consumer-protection/</a></a> or (720) 508-6000.</p>
      <p>Not more than 60 days after receipt of an <b>appeal</b> we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reason for the decisions.</p>
      <p><b>Duty not to violet the existing laws against discrimination or non-discrimination</b></p>
      <p>Such discrimination may include denying a good or service, providing a different level or quality of service, or charging different prices.</p>
      <p>We do not discriminate our users.</p>
      <p><b>HOW TO SUBMIT A REQUEST UNDER CPA?</b></p>
      <p>Only you, or someone legally authorized to act on your behalf, may make a request to <b>know</b> or <b>delete</b> related to your Personal Data. If the DSR is submitted by someone other than the consumer about whom information is being requested, proof of authorization (such as power of attorney or probate documents) will be required.</p>
      <p>We will respond to your request within 45 days after receipt of a verifiable Consumer Request and for no more than twice in a twelve-month period. We reserve the right to extend the response time by an additional 45 days when reasonably necessary and provided consumer notification of the extension is made within the first 45 days. If we refuse to take action on a request, you <b>may appeal our decision</b> within a reasonable period time by contacting us at:<u><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></u> and specifying you wish to appeal. Within 60 days of our receipt of your appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If the appeal is denied, you may submit a complaint as follows: Colorado AG at <a data-auto-recognition="true" href="https://coag.gov/file-complaint/" target="_blank"  rel="noreferrer">https://coag.gov/file-complaint/</a></p>
      <p>If you have an account with us, we may deliver our written response to that account or via email at our sole discretion. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option. You do not need to create an account for submitting a request.</p>
      <p>Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable.</p>
      <ol>
        <li><b>ADDITIONAL NOTICE TO VIRGINIA RESIDENTS</b></li>
      </ol>
      <p>Under the Virginia Consumer Data Protection Act, as amended (“<b>VCDPA</b>”) if you are a resident of Virginia acting in an individual or household context (and <b>not</b> in an employment or commercial context), you have the following rights with respect to your Personal Data.</p>
      <p>"<b>Personal data</b>" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal data" does <b>not</b> include de-identified data or publicly available information. Personal Data does not include de-identified data or publicly available data, and information excluded from the scope such as: HIPAA, GBPA, non-profit entities, higher education, employment data and FCRA, Driver's Privacy Protection Act of 1994, Family Educational Rights and Privacy Act, Farm Credit Act.</p>
      <p>The VCDPA requires Carambola discloses the Categories of data processing and the purpose of each category, as detailed</p>
      <p>in Section 3 “Data Sets we Collect and for What Purposes” of the Privacy Policy, the categories of data shared and the third parties with whom it is shared, as detailed in Section 6 “Data Sharing – Categories of Recipients we Share Personal Data With”. Disclosure of sale of data or targeted advertising are detailed in Section 7 "Users Rights and Opt Out Options" above, and in the DSR Form. Further, the table above under Section B “Additional Notice to Colorado Residents” details the rights you have under VCDPA and how you may exercise your rights.</p>
      <p><b>HOW TO SUBMIT A REQUEST UNDER VCDPA?</b></p>
      <p>We shall respond to your request within 45 days of receipt. We reserve the right to extend the response time by an additional 45 days when reasonably necessary and provided consumer notification of the extension is made within the first 45 days. If we refuse to take action on a request, you may appeal our decision within a reasonable period time by contacting us at:<u> </u><a href="mailto:contact@carambo.la" target="_self"><u><b>contact@carambo.la</b></u></a> and specifying you wish to appeal. Within 60 days of our receipt of your appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If the appeal is denied, you may submit a complaint as follows: Virginia Attorney General at <a href="https://www.oag.state.va.us/consumercomplaintform" target="_blank"  rel="noreferrer">https://www.oag.state.va.us/consumercomplaintform</a></p>
      <p>We shall provide information in response to your request free of charge, up to twice annually, unless requests are manifestly unfounded, excessive or repetitive. If we are unable to authenticate your request using commercially reasonable efforts, we may request additional information reasonably necessary to authenticate you and your request. If we cannot authenticate you and your request we will not be able to grant your request.</p>
      <ol>
        <li><b>ADDITIONAL NOTICE TO CONNECTICUT RESIDENTS</b></li>
      </ol>
      <p>Under the Connecticut Data Privacy Act, Public Act. No. 22-14 (the “<b>CDPA</b>”) if you are a resident of Connecticut, acting in an individual or household context (and <b>not</b> in a commercial or employment context or as a representative of business, non-profit or governmental entity), your rights with respect to your personal data are described below.</p>
      <p>"<b>Personal data</b>" means any information that is linked or reasonably linkable to an identified or identifiable individual. It does <b>not</b> include de-identified data or publicly available information. If further does not include information excluded from the scope such as: HIPAA, GBPA, non-profit entities, higher education, employment data and FCRA, Driver's Privacy Protection Act of 1994, Family Educational Rights and Privacy Act, Farm Credit Act.</p>
      <p>The categories of personal data processed, purpose of processing, are detailed in Section 3 “Data Sets we Collect and for What Purposes”, categories of personal data shared with third parties, categories of third parties with whom data is shared, are detailed in Section 6 “Data Sharing – Categories of Recipients we Share Personal Data With”. Disclosure of sale of data or targeted advertising are detailed in Section 7 "Users Rights and Opt Out Options" above, and in the DSR Form.</p>
      <p>Instructions on how to exercise your rights are detailed in the table above under Section B “Additional Notice to Colorado Residents” details the rights you have under CDPA and how you may exercise your rights.</p>
      <p><b>HOW TO SUBMIT A REQUEST UNDER CDPA?</b></p>
      <p>We shall respond to your request within 45 days of receipt. The response period may be extended once by 45 additional days when reasonably necessary, taking into account the complexity and number of requests and we inform you of such extension within the initial 45 days response period, together with the reason for the extension.</p>
      <p>If we decline to take action on your request, we shall so inform you without undue delay, within 45 days of receipt of your request. The notification will include a justification for declining to take action and instructions on how you may appeal. Within 60 days of our receipt of your appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If the appeal is denied, you may submit a complaint to the Connecticut Attorney General at link: <a href="https://www.dir.ct.gov/ag/complaint/" target="_blank" rel="noreferrer"><a data-auto-recognition="true" href="https://www.dir.ct.gov/ag/complaint/" target="_blank" rel="noreferrer">https://www.dir.ct.gov/ag/complaint/</a></a> or (860) 808-5318.</p>
      <p>We shall provide information in response to your request free of charge, up to twice annually, unless requests are manifestly unfounded, excessive or repetitive. If we are unable to authenticate your request using commercially reasonable efforts, we may request additional information reasonably necessary to authenticate you and your request. If we cannot authenticate you and your request, we will not be able to grant your request.</p>
      <ol>
        <li><p><b>ADDITIONAL NOTICE TO UTAH RESIDENTS (effective January 2024)</b></p></li>
      </ol>
      <p>Under the Utah Consumer Privacy Act (the “<b>UCPA</b>”) if you are a resident of Utah, acting in an individual or household context (and <b>not</b> in a commercial or employment context) your rights with respect to your personal data are described below. “<b>Personal Data</b>" refers that is linked or reasonably linkable to an identifiable individual, and does not include de-identified data and publicly available data.</p>
      <p>The categories of personal data processed, purpose of processing, are detailed in Section 3 “Data Sets we Collect and for What Purposes”, categories of personal data shared with third parties, categories of third parties with whom data is shared, are detailed in Section 6 “Data Sharing – Categories of Recipients we Share Personal Data With”. Disclosure of sale of data or targeted advertising are detailed in Section 7 "Users Rights and Opt Out Options" above, and in the DSR Form.</p>
      <p>Further, the table above under Section B “Additional Notice to Colorado Residents” details the rights you have under CDPA and how you may exercise your rights.</p>
      <ol>
        <li><p><b>NOTICE TO NEVADA RESIDENTS</b></p></li>
      </ol>
      <p>Nevada law allows Nevada residents to opt out of the sale of certain types of personal information. Subject to several exceptions, Nevada law defines “sale” to mean the exchange of certain types of personal information for monetary consideration to another person. We currently do not sell personal information as defined in the Nevada law. However, if you are a Nevada resident, you still may submit a verified request to opt out of sales and will record your instructions and incorporate them in the future if our policy changes. You may send opt-out requests to&nbsp;<a href="mailto:contact@carambo.la" target="_self"><u><b><a data-auto-recognition="true" href="mailto:contact@carambo.la">contact@carambo.la</a></b></u></a><u><b>.</b></u></p>
    </div>
  );
};

export default PrivacyPolicy;
