import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const AIArticleTest = () => {
  useEffect(() => {
    fetch('https://dev-units.carambo.la/test/ai_automation_test.json')
    .then(response => {
      if (response.ok) {
        console.log('JSON file exists.');
        // Optionally, parse and use the JSON data:
        return response.json();
      } else {
        
      fetch("https://3kmnz27mgh546ym7fcmcyrjriy0skiqu.lambda-url.us-east-1.on.aws?page_url=" + window.location.href)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json(); // parse JSON if response is JSON
      })
      .then(data => {
        console.log(data); // handle the data from the API
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
      });
    
        throw new Error('JSON file does not exist.');
      }
    })
    .then(data => {
      console.log('Parsed JSON data:', data);
    })
    .catch(error => {
      console.error('Error:', error);
    });
  
  }, []);
  return (
    <div className="privacy-policy-container">
       <Helmet>
        <title>Albert Einstein</title>
       </Helmet>      
      <h1>Albert Einstein</h1>
      <p>
      Albert Einstein remains one of the most influential figures in the history of science, a symbol of intellectual curiosity and groundbreaking thought. His work not only revolutionized our understanding of the physical universe but also reshaped the way we think about time, space, and the nature of reality. This article explores his early life, his monumental scientific contributions, his personal philosophy, and the enduring impact of his legacy.
      </p>
      <h2>Early Life and Formative Years</h2>
      <p>Born on March 14, 1879, in Ulm, Germany, Einstein exhibited a curious and questioning mind from a young age. Although he experienced a slow start in formal education—often struggling with the rigid structure of traditional schooling—his innate passion for mathematics and physics was evident early on. His self-directed studies, fueled by a deep desire to understand the mysteries of the natural world, set him apart. By the time he reached his later years in school, he had already begun formulating ideas that would later transform scientific thought.</p>
      <h2>Breakthrough in Theoretical Physics</h2>
      <p>
      Einstein’s most notable contributions began to emerge during his time working at the Swiss Patent Office in Bern. It was here that, away from the confines of academia, he developed his groundbreaking papers in 1905, a year often referred to as his “miracle year.” Among these contributions were:
      <ul>
        <li>Special Relativity: This theory revolutionized the classical mechanics of Newton, introducing the concept that the laws of physics are the same for all non-accelerating observers. The idea that time and space are relative, and not absolute, forever altered our understanding of the universe.</li>
        <li>Photoelectric Effect: His explanation of the photoelectric effect provided key evidence for the existence of quanta, laying the foundation for quantum theory. This work eventually earned him the Nobel Prize in Physics in 1921.</li>
        <li>Mass-Energy Equivalence: Perhaps his most famous equation, E=mc², emerged from his work on special relativity, illustrating the profound relationship between mass and energy. This concept has had far-reaching implications in fields ranging from nuclear physics to cosmology.</li>
      </ul>
      </p>
      <h2>Advancing the Fabric of Space and Time</h2>
      <p>
      Einstein’s quest for a deeper understanding of the universe did not stop with special relativity. He went on to develop the theory of general relativity, which provided a new description of gravity. Unlike Newton’s view of gravity as a force acting at a distance, general relativity depicted gravity as the curvature of spacetime caused by mass and energy. This insight was confirmed by observations such as the bending of light around massive objects—a prediction that was famously validated during the solar eclipse of 1919.
      </p>
      <h2>Beyond Equations: Einstein’s Philosophical and Humanistic Views</h2>
      <p>
      While Einstein is often celebrated for his scientific achievements, his philosophical reflections on life, society, and human nature are equally compelling. A passionate advocate for peace, he was deeply involved in political and social issues throughout his life. His writings and speeches revealed a man who grappled with ethical questions and sought to use his influence for the betterment of humanity. Whether addressing the perils of nuclear weapons or advocating for civil rights, Einstein’s voice resonated far beyond the realm of academia.
      </p>
      <h2>The Legacy of a Genius</h2>
      <p>
      The influence of Einstein’s work extends well into the present day. His theories underpin much of modern physics, guiding research in cosmology, quantum mechanics, and beyond. Educational institutions, scientific research, and popular culture all bear the mark of his genius. Portraits of his thoughtful gaze have become iconic, symbolizing the power of human curiosity and intellect.
      </p>
      <p>
Einstein’s life also serves as a reminder that the path to innovation often requires thinking beyond conventional boundaries. His persistence in questioning established norms and his willingness to embrace uncertainty continue to inspire new generations of scientists, philosophers, and creative minds around the world.
      </p>
      <h2>Concluding Thoughts</h2>
      <p>
      Albert Einstein’s journey from a curious child in Germany to one of the greatest scientific minds in history is a testament to the power of imagination and perseverance. His theories not only deepened our understanding of the cosmos but also challenged us to think differently about our place in the universe. Today, as we explore the frontiers of science and technology, Einstein’s legacy continues to remind us that the pursuit of knowledge is a timeless and ever-evolving adventure.
      </p>
      <p>
      This article captures only a glimpse of Einstein’s multifaceted contributions and his profound impact on both science and society. His work remains a beacon for those who dare to explore the unknown and a reminder that great ideas often arise from a relentless curiosity about the world.
      </p>
    </div>
  );
};

export default AIArticleTest;
