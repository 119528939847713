import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import NotFound from './NotFound';

const categories = {
    'healthcare': {
        title: 'Healthcare',
        links: [
            { name: 'AI-Powered Early Disease Detection: Revolutionizing Preventive Care', url: '/article/1' },
            { name: 'Robotic Surgery Advancements: How AI is Enhancing Surgical Precision', url: '/article/2' },
            { name: 'AI in Drug Discovery: Accelerating the Development of Life-Saving Medications', url: '/article/3' },
            { name: 'Personalized Treatment Plans: AI\'s Role in Tailoring Healthcare to Individual Patients', url: '/article/4' },
            { name: 'AI-Assisted Diagnostics: Improving Accuracy and Efficiency in Medical Imaging', url: '/article/5' },
        ],
    },
    'finance_and_banking': {
        title: 'Finance and Banking',
        links: [
            { name: 'AI-Driven Fraud Detection: Safeguarding Financial Transactions in Real-Time', url: '/article/6' },
            { name: 'Robo-Advisors: The Future of Personalized Investment Management', url: '/article/7' },
            { name: 'Predictive Analytics in Banking: How AI is Revolutionizing Risk Assessment', url: '/article/8' },
            { name: 'Chatbots and Virtual Assistants: Enhancing Customer Service in Financial Institutions', url: '/article/9' },
            { name: 'AI in Algorithmic Trading: Transforming Stock Market Strategies', url: '/article/10' },
        ],
    },
    'education': {
        title: 'Education',
        links: [
            { name: 'Adaptive Learning Platforms: Personalizing Education with AI', url: '/article/11' },
            { name: 'AI-Powered Tutoring Systems: 24/7 Support for Students', url: '/article/12' },
            { name: 'Automated Grading and Feedback: Enhancing Assessment Efficiency with AI', url: '/article/13' },
            { name: 'Predictive Analytics in Education: Using AI to Improve Student Outcomes', url: '/article/14' },
            { name: 'AI-Enhanced Language Learning: Breaking Down Global Communication Barriers', url: '/article/15' },
        ],
    },
    'manufacturing': {
        title: 'Manufacturing',
        links: [
            { name: 'AI-Driven Predictive Maintenance: Revolutionizing Industrial Equipment Upkeep', url: '/article/16' },
            { name: 'Smart Factories: AI-Powered Automation and Process Optimization', url: '/article/17' },
            { name: 'AI in Supply Chain Management: Enhancing Efficiency and Resilience', url: '/article/18' },
            { name: 'AI-Powered Quality Control: Ensuring Precision in Production', url: '/article/19' },
            { name: 'AI-Enabled Product Design and Development: Accelerating Innovation in Manufacturing', url: '/article/20' },
        ],
    },
    'retail_and_e_commerce': {
        title: 'Retail and E-commerce',
        links: [
            { name: 'AI-Powered Personalization: Revolutionizing the Online Shopping Experience', url: '/article/21' },
            { name: 'AI in Inventory Management: Optimizing Stock Levels and Supply Chains', url: '/article/22' },
            { name: 'AI-Driven Customer Service: Enhancing Support and Engagement in Retail', url: '/article/23' },
            { name: 'AI in Pricing and Promotions: Maximizing Revenue and Customer Value', url: '/article/24' },
            { name: 'AI in Visual Search and Product Discovery: Revolutionizing Online Shopping', url: '/article/25' },
        ],
    },
    // Add more categories as needed
};

const CategoryPage = () => {
    const { title } = useParams();  // Get the category title from the URL
    const category = categories[title.toLowerCase()];  // Fetch the category data

    if (!category) {
        return (<NotFound />);
    }

    return (
        <div className="category-container">
            <Helmet>
                <title>Carambola AI - {category.title}</title>
            </Helmet>
            <h1>{category.title}</h1>
            <ul>
                {category.links.map((link, index) => (
                    <li key={index}>
                        <Link to={link.url}>{link.name}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CategoryPage;