import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const categories = {
  'healthcare': {
      title: 'Healthcare',
      links: [
          { name: 'AI-Powered Early Disease Detection: Revolutionizing Preventive Care', url: '/article/1', first_paragraph: 'Artificial Intelligence (AI) is rapidly transforming the landscape of healthcare, and one of its most promising applications is in the realm of early disease detection. This cutting-edge technology is revolutionizing preventive care, offering new hope for patients and healthcare providers alike...' },
          { name: 'Robotic Surgery Advancements: How AI is Enhancing Surgical Precision', url: '/article/2', first_paragraph: 'In recent years, the field of surgery has witnessed a remarkable transformation with the integration of artificial intelligence (AI) and robotics. This fusion of cutting-edge technologies is revolutionizing surgical procedures, offering unprecedented levels of precision, control, and patient outcomes. As we delve into the world of AI-enhanced robotic surgery, it becomes clear that we are on the cusp of a new era in medical interventions...'},
      ],
  },
  'finance_and_banking': {
      title: 'Finance and Banking',
      links: [
          { name: 'AI-Driven Fraud Detection: Safeguarding Financial Transactions in Real-Time', url: '/article/6', first_paragraph: 'In the rapidly evolving landscape of finance and banking, artificial intelligence (AI) has emerged as a game-changer in the fight against fraud. As financial transactions become increasingly digital and complex, traditional fraud detection methods are struggling to keep pace with sophisticated cybercriminals. AI-driven fraud detection systems are stepping in to fill this gap, offering real-time protection and unprecedented accuracy in identifying suspicious activities...' },
          { name: 'Robo-Advisors: The Future of Personalized Investment Management', url: '/article/7', first_paragraph: 'The financial industry is witnessing a significant shift in investment management with the rise of robo-advisors. These AI-powered platforms are revolutionizing the way individuals approach investing, offering personalized, automated financial advice and portfolio management at a fraction of the cost of traditional human advisors...' },
      ],
  },
  'education': {
      title: 'Education',
      links: [
          { name: 'Adaptive Learning Platforms: Personalizing Education with AI', url: '/article/11', first_paragraph: "The education landscape is undergoing a significant transformation with the advent of adaptive learning platforms powered by artificial intelligence (AI). These innovative systems are revolutionizing the way students learn by providing personalized educational experiences tailored to each individual's needs, abilities, and learning pace..." },
          { name: 'AI-Powered Tutoring Systems: 24/7 Support for Students', url: '/article/12', first_paragraph: 'Artificial Intelligence (AI) is revolutionizing the field of education through the development of sophisticated AI-powered tutoring systems. These digital tutors are providing students with round-the-clock access to personalized learning support, fundamentally changing the landscape of educational assistance and supplementary instruction...' },
      ],
  },
  'manufacturing': {
      title: 'Manufacturing',
      links: [
          { name: 'AI-Driven Predictive Maintenance: Revolutionizing Industrial Equipment Upkeep', url: '/article/16', first_paragraph: "Artificial Intelligence (AI) is transforming the manufacturing industry, and one of its most impactful applications is in the realm of predictive maintenance. This innovative approach is revolutionizing how industrial equipment is maintained, leading to increased efficiency, reduced downtime, and significant cost savings..." },
          { name: 'Smart Factories: AI-Powered Automation and Process Optimization', url: '/article/17', first_paragraph: "The concept of smart factories, powered by Artificial Intelligence (AI), is revolutionizing the manufacturing landscape. These advanced facilities leverage AI, Internet of Things (IoT) technologies, and data analytics to create highly automated and optimized production environments. The result is a new era of manufacturing characterized by unprecedented levels of efficiency, flexibility, and productivity..." },
      ],
  },
  'retail_and_e_commerce': {
      title: 'Retail and E-commerce',
      links: [
          { name: 'AI-Powered Personalization: Revolutionizing the Online Shopping Experience', url: '/article/21', first_paragraph: "Artificial Intelligence (AI) is transforming the landscape of retail and e-commerce, with personalization emerging as one of its most impactful applications. By leveraging machine learning algorithms and vast amounts of customer data, AI is enabling retailers to create highly tailored shopping experiences that cater to individual preferences, behaviors, and needs..." },
          { name: 'AI in Inventory Management: Optimizing Stock Levels and Supply Chains', url: '/article/22', first_paragraph: "Artificial Intelligence (AI) is revolutionizing inventory management in the retail and e-commerce sectors, bringing unprecedented levels of efficiency, accuracy, and responsiveness to supply chain operations. By leveraging machine learning algorithms, predictive analytics, and real-time data processing, AI is enabling retailers to optimize their stock levels, reduce costs, and improve customer satisfaction..." },
      ],
  },
  // Add more categories as needed
};

const Home = () => {
  const category_healthcare = categories['healthcare']; 
  const category_finance_and_banking = categories['finance_and_banking']; 
  const category_education = categories['education']; 
  const category_manufacturing = categories['manufacturing']; 
  const category_retail_and_e_commerce = categories['retail_and_e_commerce']; 

  return (
    <div className="home-container">
        <Helmet>
            <title>Carambola AI - Revolutionize Monetization with Carambola AI Social Units</title>
        </Helmet>
      <h1>Revolutionize Monetization with Carambola AI Social Units</h1>
      <div className="home-categories-list-container">
        <div className="home-category-item">
          <h2><a href="/category/healthcare">{category_healthcare.title}</a></h2>
          <ul>
              {category_healthcare.links.map((link, index) => (
                  <li key={index}>
                    <div className='div-row'>
                      <Link to={link.url}>{link.name}</Link>
                      </div>
                      <p>{link.first_paragraph}</p>
                  </li>
              ))}
          </ul>
        </div>
        <div className="home-category-item">
          <h2><a href="/category/finance_and_banking">{category_finance_and_banking.title}</a></h2>
          <ul>
              {category_finance_and_banking.links.map((link, index) => (
                  <li key={index}>
                    <div className='div-row'>
                      <Link to={link.url}>{link.name}</Link>
                    </div>
                      <p>{link.first_paragraph}</p>
                  </li>
              ))}
          </ul>
        </div>
        <div className="home-category-item">
          <h2><a href="/category/education">{category_education.title}</a></h2>
          <ul>
              {category_education.links.map((link, index) => (
                  <li key={index}>
                     <div className='div-row'>
                       <Link to={link.url}>{link.name}</Link>
                      </div>
                      <p>{link.first_paragraph}</p>
                  </li>
              ))}
          </ul>
        </div>
        <div className="home-category-item">
          <h2><a href="/category/manufacturing">{category_manufacturing.title}</a></h2>
          <ul>
              {category_manufacturing.links.map((link, index) => (
                  <li key={index}>
                     <div className='div-row'>
                       <Link to={link.url}>{link.name}</Link>
                      </div>
                      <p>{link.first_paragraph}</p>
                  </li>
              ))}
          </ul>
        </div>
        <div className="home-category-item">
          <h2><a href="/category/retail_and_e_commerce">{category_retail_and_e_commerce.title}</a></h2>
          <ul>
              {category_retail_and_e_commerce.links.map((link, index) => (
                  <li key={index}>
                     <div className='div-row'>
                       <Link to={link.url}>{link.name}</Link>
                      </div>
                      <p>{link.first_paragraph}</p>
                  </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Home;