import React from 'react';
import { Helmet } from 'react-helmet';

const NotFound = () => {
    return (
        <div className="category-container">
            <Helmet>
                <title>Carambola AI - 404 - Not Found</title>
            </Helmet>
            <h1>Carambola AI - 404 - Not Found</h1>
        </div>
    );
};

export default NotFound;